import { usePictureSources } from 'cross-selling/hooks/usePictureSources'
import type { PointOfInterestImage } from 'landings/Home/Home.type'
import { pointOfInterestImageLoaderFactory } from 'landings/utils/pointOfInterestImageLoaderFactory'
import { getImageProps } from 'next/image'
import type { CSSProperties } from 'react'
import { cx } from 'utils/cx'

import styles from './HeroBannerImage.module.scss'

type FullDeviceHeightCalc = Record<DEVICE_TYPE, (width: number) => number>
type DeviceHeightCalc = Partial<FullDeviceHeightCalc>

type HeroBannerImageProps = {
	readonly image?: PointOfInterestImage | null
	readonly landscapeImage?: PointOfInterestImage | null
	readonly portraitImage?: PointOfInterestImage | null
	readonly className?: string
	readonly deviceHeightCalc?: DeviceHeightCalc
	readonly isAlwaysPortrait?: boolean
	readonly priority?: boolean
}

const ASPECT_RATIO_SMALL_WIDTH = 5
const ASPECT_RATIO_SMALL_HEIGHT = 7

const ASPECT_RATIO_MEDIUM_HEIGHT = 600

const ASPECT_RATIO_LARGE_WIDTH = 7
const ASPECT_RATIO_LARGE_HEIGHT = 3

const ASPECT_RATIO_SMALL = ASPECT_RATIO_SMALL_WIDTH / ASPECT_RATIO_SMALL_HEIGHT
const ASPECT_RATIO_LARGE = ASPECT_RATIO_LARGE_WIDTH / ASPECT_RATIO_LARGE_HEIGHT

export enum DEVICE_TYPE {
	Mobile = 'mobile',
	Tablet = 'tablet',
	Desktop = 'desktop',
}

const defaultDeviceHeightCalc: FullDeviceHeightCalc = {
	[DEVICE_TYPE.Mobile]: (width) => width / ASPECT_RATIO_SMALL,
	[DEVICE_TYPE.Tablet]: () => ASPECT_RATIO_MEDIUM_HEIGHT,
	[DEVICE_TYPE.Desktop]: (width) => width / ASPECT_RATIO_LARGE,
}

export function HeroBannerImage({
	className,
	image,
	landscapeImage,
	portraitImage,
	isAlwaysPortrait,
	deviceHeightCalc,
	priority = false,
}: HeroBannerImageProps) {
	const { mediaSmall, mediaMedium, mediaLarge } = usePictureSources()

	const PERCENTAGE = 0.01
	const deviceHeightCalcMap = {
		...defaultDeviceHeightCalc,
		...deviceHeightCalc,
	}

	if (!landscapeImage && !portraitImage && !image) {
		return null
	}

	const landscape = (landscapeImage ??
		image ??
		portraitImage) as PointOfInterestImage

	const portrait = (portraitImage ??
		image ??
		landscapeImage) as PointOfInterestImage

	const landscapePointOfInterest = {
		x: landscape.width * landscape.pointOfInterest.percentageX * PERCENTAGE,
		y: landscape.height * landscape.pointOfInterest.percentageY * PERCENTAGE,
	}
	const portraitPointOfInterest = {
		x: portrait.width * portrait.pointOfInterest.percentageX * PERCENTAGE,
		y: portrait.height * portrait.pointOfInterest.percentageY * PERCENTAGE,
	}
	const heroBannerSmallImageLoader = pointOfInterestImageLoaderFactory(
		portraitPointOfInterest,
		deviceHeightCalcMap[DEVICE_TYPE.Mobile]
	)
	const heroBannerMediumImageLoader = pointOfInterestImageLoaderFactory(
		portraitPointOfInterest,
		deviceHeightCalcMap[DEVICE_TYPE.Tablet]
	)
	const heroBannerLargeImageLoader = pointOfInterestImageLoaderFactory(
		landscapePointOfInterest,
		deviceHeightCalcMap[DEVICE_TYPE.Desktop]
	)
	const commonImageProps = {
		className: cx(className, styles.heroBannerImage),
		fill: true,
		'aria-hidden': true,
		priority,
	}

	const {
		props: { srcSet: mobileSrcSet },
	} = getImageProps({
		...commonImageProps,
		src: portrait.url,
		alt: portrait.description ?? '',
		loader: heroBannerSmallImageLoader,
	})
	const {
		props: { srcSet: tabletSrcSet },
	} = getImageProps({
		...commonImageProps,
		src: portrait.url,
		alt: portrait.description ?? '',
		loader: heroBannerMediumImageLoader,
	})
	const {
		props: { srcSet: desktopSrcSet, ...restImageProps },
	} = getImageProps({
		...commonImageProps,
		src: landscape.url,
		alt: landscape.description ?? '',
		loader: heroBannerLargeImageLoader,
	})

	const pictureSources = []
	if (isAlwaysPortrait) {
		pictureSources.push(<source key={'portrait'} srcSet={mobileSrcSet} />)
	} else {
		pictureSources.push(
			<source key={'desktop'} media={mediaLarge} srcSet={desktopSrcSet} />,
			<source key={'tablet'} media={mediaMedium} srcSet={tabletSrcSet} />,
			<source key={'mobile'} media={mediaSmall} srcSet={mobileSrcSet} />
		)
	}

	return (
		<picture>
			{...pictureSources}
			<img
				alt={portrait.description ?? ''}
				{...restImageProps}
				className={restImageProps.className}
				style={{ ...restImageProps.style } as CSSProperties}
				draggable='false'
			/>
		</picture>
	)
}
