'use client'

import {
	Fragment,
	type ReactElement,
	cloneElement,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react'
import { cx } from 'utils/cx'

import { MarqueeContext } from './MarqueeProvider'

import styles from './Marquee.module.scss'

interface MarqueeProps {
	readonly children: ReactElement
	readonly className?: string
}

const renderChildrenWithAriaHidden = (children: ReactElement) => {
	return cloneElement(children, {
		'aria-hidden': true,
	})
}

export const Marquee = ({ children, className }: MarqueeProps) => {
	const containerRef = useRef<HTMLDivElement>(null)

	const { isAnimationPaused } = useContext(MarqueeContext)

	const [textArray, setTextArray] = useState<number[]>([1])
	const [canStartAnimation, setCanStartAnimation] = useState(false)

	useEffect(() => {
		if (textArray.length === 1) {
			const container = containerRef.current

			const containerWidth = container?.clientWidth || 0
			const elementWidth = container?.children[0]?.clientWidth || 0

			const textMultiplier = Math.ceil(containerWidth / elementWidth) + 1

			const arrayCounter = []
			for (let i = 1; i <= textMultiplier; i++) {
				arrayCounter.push(i)
			}

			setTextArray(arrayCounter)
			setCanStartAnimation(true)
		}
	}, [containerRef.current])

	return (
		<div className={cx(className, styles.marquee)}>
			{!isAnimationPaused ? (
				<Fragment>
					<div
						className={cx(
							styles.marqueeGroup,
							canStartAnimation && styles.animated,
							textArray.length === 1 && styles.hidden
						)}
						ref={containerRef}
					>
						{textArray.map((value) => (
							<Fragment key={`${value}`}>
								{value === 1
									? children
									: renderChildrenWithAriaHidden(children)}
							</Fragment>
						))}
					</div>
					<div
						className={cx(
							styles.marqueeGroup,
							canStartAnimation && styles.animated,
							textArray.length === 1 && styles.hidden
						)}
					>
						{textArray.map((value) => (
							<Fragment key={`${value}`}>
								{renderChildrenWithAriaHidden(children)}
							</Fragment>
						))}
					</div>
				</Fragment>
			) : (
				<div className={cx(styles.pausedMarqueeGroup)}>
					{textArray.map((value) => (
						<Fragment key={`${value}`}>
							{value === 1 ? children : renderChildrenWithAriaHidden(children)}
						</Fragment>
					))}
				</div>
			)}
		</div>
	)
}
