'use client'

import { pointOfInterestImageLoaderFactory } from 'landings/utils/pointOfInterestImageLoaderFactory'
import Image, { type ImageProps } from 'next/image'

interface ImageWrapperProps extends ImageProps {
	readonly pointOfInterest: {
		x: number
		y: number
	}
}

export const ImageWrapper = ({
	pointOfInterest,
	alt = '',
	...rest
}: ImageWrapperProps) => {
	const imageLoader = pointOfInterestImageLoaderFactory(
		pointOfInterest,
		(width) => width
	)

	return <Image {...rest} alt={alt} loader={imageLoader} />
}
