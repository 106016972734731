import { sendSelectPromotionEvent } from 'analytics/events/eec/selectPromotion/selectPromotion'
import { BUTTON_VARIANT } from 'fukku/Button/Button.types'
import { ClientLinkPrimary } from 'fukku/Button/ClientLink/Primary'
import { useEffect, useRef, useState } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { cx } from 'utils/cx'

import type { Cta } from '../../../../Home.type'

import styles from './ButtonCtas.module.scss'
import primaryButtonStyles from 'fukku/Button/ClientLink/Primary/ClientLinkPrimary.module.scss'

interface ButtonCtasProp {
	readonly ctas: Cta[]
	readonly activeLink?: boolean
	readonly className?: string
	readonly buttonClassName?: string
	readonly listenTextOverflow?: boolean
}

const BUTTON_TEXT_CLASSNAME = 'buttonTextElement'

export function ButtonCtas({
	ctas,
	activeLink,
	className,
	buttonClassName,
	listenTextOverflow,
}: ButtonCtasProp) {
	const ctaWrapperRef = useRef<HTMLDivElement>(null)
	const ctaWrapper = ctaWrapperRef.current

	const { isSmall } = useResponsive()

	const [isFullWidthCta, setIsFullWidthCta] = useState(false)

	useEffect(() => {
		const handleCtasResize = () => {
			const elements = Array.from(
				ctaWrapper?.querySelectorAll(`.${BUTTON_TEXT_CLASSNAME}`) ?? []
			)
			const someOverflowElement =
				elements.length > 1 &&
				elements.some((element) => element.scrollWidth > element.clientWidth)
			const isFullWidth = isSmall && elements.length > 1 && someOverflowElement

			if (!isSmall) {
				setIsFullWidthCta(false)
			}

			if (isFullWidth) {
				setIsFullWidthCta(true)
			}
		}
		if (listenTextOverflow) {
			handleCtasResize()
			window?.addEventListener('resize', handleCtasResize)
		}

		return () => {
			if (listenTextOverflow) {
				window?.removeEventListener('resize', handleCtasResize)
			}
		}
	}, [isSmall, ctaWrapper])

	if (!ctas?.length) {
		return null
	}

	return (
		<div className={className} ref={ctaWrapperRef}>
			{ctas.length === 1 ? (
				<div
					className={cx(
						primaryButtonStyles.inverse,
						activeLink && styles.activeCta
					)}
				>
					{ctas[0].text}
				</div>
			) : (
				ctas.map(
					({ ctaId, text, href, linkProps, promotion, withLegacyLocale }) => (
						<ClientLinkPrimary
							key={ctaId}
							href={href}
							onClick={() => sendSelectPromotionEvent(promotion)}
							variant={BUTTON_VARIANT.INVERSE}
							fullWidth={isFullWidthCta}
							className={buttonClassName}
							withLegacyLocale={withLegacyLocale}
							{...linkProps}
						>
							<span
								className={cx(
									styles.buttonTextContainer,
									BUTTON_TEXT_CLASSNAME
								)}
							>
								{text}
							</span>
						</ClientLinkPrimary>
					)
				)
			)}
		</div>
	)
}
