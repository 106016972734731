'use client'

import { type ReactNode, useRef } from 'react'

import { BannerWrapper } from '../../../BannerWrapper/BannerWrapper'

interface BannerRootWrapperProps {
	readonly children: ReactNode
	readonly isPrioritySlot?: boolean
	readonly className?: string
}

export function BannerRootWrapper({
	isPrioritySlot,
	children,
	className,
}: BannerRootWrapperProps) {
	const ref = useRef<HTMLDivElement>(null)

	return (
		<BannerWrapper ref={ref} priority={isPrioritySlot} className={className}>
			{children}
		</BannerWrapper>
	)
}
