'use client'

import { Countdown } from 'fukku/Countdown/Countdown'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { cx } from 'utils/cx'

import styles from './HeroBannerPromoCountdown.module.scss'
import text from 'fukku/styles/texts.module.scss'

export const HeroBannerPromoCountdown = ({
	date,
}: {
	readonly date: string
}) => {
	const { isLessThanLarge } = useResponsive()

	const numbersClassName = isLessThanLarge ? text.titleXL : text.titleXXL
	const lettersClassName = isLessThanLarge ? text.titleM : text.titleXL

	return (
		<Countdown
			date={date}
			className={cx(styles.heroBannerPromoCountdown, lettersClassName)}
			numbersClassName={numbersClassName}
		/>
	)
}
