'use client'

import type { Cta, PointOfInterestImage } from 'landings/Home/Home.type'
import { useRef } from 'react'

import { ButtonCtas } from '../ctas/ButtonCtas/ButtonCtas'
import { useActiveLink } from '../ctas/hooks/onActiveLink'
import { HeroBannerSecondaryLinesImage } from './HeroBannerSecondaryLinesImage/HeroBannerSecondaryLinesImage'
import { HeroBannerSecondaryLinesTitles } from './HeroBannerSecondaryLinesTitles/HeroBannerSecondaryLinesTitles'

import styles from './HeroBannerSecondaryLines.module.scss'

type HeroBannerSecondaryLinesComponentProps = {
	readonly title: string
	readonly subTitle: string
	readonly image: PointOfInterestImage
	readonly ctas: Cta[]
	readonly isPrioritySlot: boolean
}

export function HeroBannerSecondaryLinesContent({
	title,
	subTitle,
	image,
	ctas,
	isPrioritySlot,
}: HeroBannerSecondaryLinesComponentProps): React.ReactNode {
	const ref = useRef<HTMLDivElement>(null)
	const activeLink = useActiveLink(ref)

	return (
		<div className={styles.bannerWrapper} ref={ref}>
			<HeroBannerSecondaryLinesImage image={image} priority={isPrioritySlot} />
			<div className={styles.bannerContainer}>
				<HeroBannerSecondaryLinesTitles
					subTitle={subTitle}
					title={title}
					isSingleCta={ctas.length === 1}
				/>
				<ButtonCtas
					ctas={ctas}
					activeLink={activeLink}
					className={styles.ctas}
				/>
			</div>
		</div>
	)
}
