'use client'

import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import {
	type HeroBannerPromo,
	HeroBannerPromoType,
} from '../../../../Home.type'

import styles from './HeroBannerPromoProgressive.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface HeroBannerPromoProgressiveProps {
	readonly section: HeroBannerPromo
}

export function HeroBannerPromoProgressive({
	section,
}: HeroBannerPromoProgressiveProps) {
	const { isLargeOrGreater } = useResponsive()
	if (section.promoType !== HeroBannerPromoType.progressive) {
		return null
	}

	const {
		progressivePromoTitles: { title1, strategy1, title2, strategy2 },
	} = section

	return (
		<div className={styles.titles}>
			<h2 className={styles.titlesWrapper}>
				<div
					className={
						isLargeOrGreater
							? text.inspirationalTitleXL
							: text.inspirationalTitleL
					}
				>
					{title1}
				</div>
				<div className={text.titleM}>{strategy1}</div>
				<div
					className={
						isLargeOrGreater
							? text.inspirationalTitleXL
							: text.inspirationalTitleL
					}
				>
					{title2}
				</div>
				<div className={text.titleM}>{strategy2}</div>
			</h2>
		</div>
	)
}
