'use client'

import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { cx } from 'utils/cx'

import motionStyles from '../../../MotionAnimations.module.scss'
import styles from '../FamilyBannerShopContent.module.scss'

interface FamilyBannerShopTextProps {
	readonly titleText: string
	readonly ctaText?: string
}

export function FamilyBannerShopText({
	titleText,
	ctaText,
}: FamilyBannerShopTextProps): React.ReactNode {
	const { isLargeOrGreater } = useResponsive()

	return (
		<div className={cx(styles.textWrapper, motionStyles.opacityFadeIn)}>
			<h2 className={styles.familyBannerTitle}>{titleText}</h2>
			{isLargeOrGreater && ctaText && (
				<span className={styles.cta}>{ctaText}</span>
			)}
		</div>
	)
}
