'use client'

import type { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import dynamic from 'next/dynamic'
import { Suspense } from 'react'

const AbTestVariant = dynamic(
	async () => (await import('./AbTestVariant')).AbTestVariant
)
interface AbTestVariantProps {
	readonly children: React.ReactNode
	readonly flagId: featureFlagKeys
	readonly variant: number
}

export function AbTestVariantWrapper({
	children,
	flagId,
	variant,
}: AbTestVariantProps) {
	return (
		<Suspense fallback={null}>
			<AbTestVariant flagId={flagId} variant={variant}>
				{children}
			</AbTestVariant>
		</Suspense>
	)
}
