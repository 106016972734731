'use client'

import { useIntersectionObserver } from 'hooks/useIntersectionObserver/useIntersectionObserver'
import { type CSSProperties, useEffect, useRef, useState } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import { FamilyBannerShopText } from './FamilyBannerShopText/FamilyBannerShopText'

import styles from './FamilyBannerShopContent.module.scss'

type FamilyBannerShopContentProps = {
	readonly titleText: string
	readonly inverse: boolean
	readonly ctaText?: string
}

const ONE_PERCENT = 0.01
const ONE_HUNDRED = 100
const ONE_HUNDRED_ONE = 101

function calculateOpacity(visibleHeight: number, divHeight: number) {
	return visibleHeight / divHeight
}

export const FamilyBannerShopContent = ({
	titleText,
	ctaText,
	inverse,
}: FamilyBannerShopContentProps) => {
	const ref = useRef<HTMLDivElement>(null)
	const refEndBanner = useRef<HTMLDivElement>(null)
	const refText = useRef<HTMLDivElement>(null)
	const refTextPlaceHolder = useRef<HTMLDivElement>(null)
	const entryBanner = useIntersectionObserver(ref, {
		threshold: ONE_PERCENT,
	})
	const entryEndBanner = useIntersectionObserver(refEndBanner, {
		threshold: 1,
	})
	const entryEndText = useIntersectionObserver(refTextPlaceHolder, {
		threshold: 1,
	})
	const [opacity, setOpacity] = useState<number>(0)

	const isIntersectingBanner = entryBanner?.isIntersecting
	const isIntersectingEndTextBanner = entryEndText?.isIntersecting
	const isIntersectingEndBanner = entryEndBanner?.isIntersecting

	const isFixed =
		isIntersectingBanner &&
		(isIntersectingEndTextBanner || opacity === 1) &&
		!isIntersectingEndBanner

	const isAbsoluteTextBottom = isIntersectingEndBanner

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (
						entry.isIntersecting &&
						refTextPlaceHolder?.current?.offsetHeight
					) {
						const textDivHeight = refTextPlaceHolder?.current?.offsetHeight
						const visibleHeight = Math.min(
							entry.intersectionRect.height,
							textDivHeight
						)
						const visibility = calculateOpacity(visibleHeight, textDivHeight)
						setOpacity(isIntersectingEndBanner ? 1 : visibility)
					}
				})
			},
			{
				threshold: Array.from(Array(ONE_HUNDRED_ONE).keys()).map(
					(i) => i / ONE_HUNDRED
				),
			}
		)

		if (ref.current) {
			observer.observe(ref.current)
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current)
			}
		}
	}, [])

	return (
		<div
			ref={ref}
			className={cx(
				styles.familyContentBannerWrapper,
				applyStylesIf(!!isFixed, styles.fixedText),
				applyStylesIf(!!isAbsoluteTextBottom, styles.normalTextBottom)
			)}
			style={{ '--text-opacity': opacity } as CSSProperties}
		>
			<div
				className={styles.textPlaceholder}
				ref={refTextPlaceHolder}
				style={
					{
						'--div-height': `${refText?.current?.offsetHeight}px`,
					} as CSSProperties
				}
			/>
			<div
				className={cx(
					styles.textContainer,
					applyStylesIf(inverse, styles.inverse)
				)}
				ref={refText}
			>
				<FamilyBannerShopText titleText={titleText} ctaText={ctaText} />
			</div>
			<div ref={refEndBanner} className={styles.endBanner} />
		</div>
	)
}
