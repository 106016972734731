'use client'

import {
	type Cta,
	HeroBannerShopType,
	type PointOfInterestImage,
	type Video,
} from 'landings/Home/Home.type'
import dynamic from 'next/dynamic'
import { useState } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { cx } from 'utils/cx'
import { SimpleVideo } from 'videos/components/SimpleVideo/SimpleVideo'

import { ButtonCtas } from '../../../../ctas/ButtonCtas/ButtonCtas'
import { HeroBannerImage } from '../../../components/HeroBannerImage/HeroBannerImage'

import animationStyles from '../../../../MotionAnimations.module.scss'
import styles from './HeroBannerShopSalesContent.module.scss'

const HeroBannerShopSalesText = dynamic(() =>
	import('../HeroBannerShopSalesText/HeroBannerShopSalesText').then(
		(mod) => mod.HeroBannerShopSalesText
	)
)

interface HeroBannerShopSalesContentProps {
	readonly isCicd: boolean
	readonly isPrioritySlot: boolean
	readonly type: HeroBannerShopType
	readonly video: {
		videoPortrait?: Video
		videoLandscape?: Video
		videoPlaceholder?: PointOfInterestImage | null
	} | null
	readonly videoButtonTitle: {
		play: string
		pause: string
	}
	readonly titleKey: string
	readonly inverse: boolean
	readonly ctas: Cta[]
	readonly subtitleTopKey?: string
	readonly discount?: string
	readonly subtitleBottomGroup?: {
		titleKey: string
		subtitleKey: string
	}[]
	readonly subtitleBottomKey?: string
}

export const HeroBannerShopSalesContent = ({
	isCicd,
	isPrioritySlot,
	type,
	video,
	videoButtonTitle,
	subtitleTopKey,
	titleKey,
	discount,
	subtitleBottomGroup,
	subtitleBottomKey,
	inverse,
	ctas,
}: HeroBannerShopSalesContentProps) => {
	const isVideo = type === HeroBannerShopType.video
	const { isLargeOrGreater } = useResponsive()
	const [canRenderText, setCanRenderText] = useState(!isVideo)

	const handleVideoCanPlay = () => {
		setCanRenderText(true)
	}

	return (
		<>
			{isVideo && video ? (
				<SimpleVideo
					disableAutoplay={isCicd}
					video={video}
					inverse={inverse}
					videoButtonTitle={videoButtonTitle}
					videoButtonClassName={cx(styles.videoButton, styles.opacityFadeIn)}
					onVideoCanPlay={handleVideoCanPlay}
					contentIfVideoCantPlay={
						<HeroBannerImage
							image={video.videoPlaceholder}
							priority={isPrioritySlot}
						/>
					}
				/>
			) : null}
			{canRenderText ? (
				<div
					className={cx(styles.contentWrapper, animationStyles.opacityFadeIn)}
				>
					<HeroBannerShopSalesText
						subtitleTopKey={subtitleTopKey}
						titleKey={titleKey}
						discount={discount}
						subtitleBottomGroup={subtitleBottomGroup}
						subtitleBottomKey={subtitleBottomKey}
						inverse={inverse}
						isLargeOrGreater={isLargeOrGreater}
					/>
					<ButtonCtas
						ctas={ctas}
						className={styles.ctaWrapper}
						buttonClassName={styles.buttonCta}
						listenTextOverflow
					/>
				</div>
			) : null}
		</>
	)
}
