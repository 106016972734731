'use client'

import styles from './HeroBannerSecondaryLinesTitles.module.scss'

type HeroBannerSecondaryLinesTitlesProps = {
	readonly subTitle: string
	readonly title: string
	readonly isSingleCta: boolean
}

export function HeroBannerSecondaryLinesTitles({
	subTitle,
	title,
	isSingleCta,
}: HeroBannerSecondaryLinesTitlesProps): React.ReactNode {
	const titles = (
		<>
			<p className={styles.subTitle}>{subTitle}</p>
			<div className={styles.title}>{title}</div>
		</>
	)
	if (isSingleCta) {
		return <div className={styles.bannerTitles}>{titles}</div>
	}

	return <h2 className={styles.bannerTitles}>{titles}</h2>
}
