'use client'

import { useSession } from 'session/src/hooks/useSession'

type UserLoggedInProps = {
	readonly children: React.ReactNode
}
export function UserLoggedIn({ children }: UserLoggedInProps): React.ReactNode {
	const { logged } = useSession()

	if (logged) {
		return null
	}
	return children
}
