'use client'

import { SelectPromotion } from 'analytics/components/SelectPromotion/SelectPromotion'
import dynamic from 'next/dynamic'
import { applyStylesIf, cx } from 'utils/cx'

import type { Cta } from '../../../../Home.type'

import styles from './PromotionalBannerCtas.module.scss'

const ButtonLink = dynamic(() =>
	import('fukku/Button/ButtonLink').then((mod) => mod.ButtonLink)
)

interface PromotionalBannerCtas {
	readonly ctas: Cta[]
	readonly isLow: boolean
	readonly isSingleCta: boolean
}

export const PromotionalBannerCtas = ({
	ctas,
	isLow,
	isSingleCta,
}: PromotionalBannerCtas) => {
	return (
		<div
			className={cx(
				styles.ctasWrapper,
				applyStylesIf(isLow, styles.low),
				applyStylesIf(isSingleCta, styles.singleCta)
			)}
		>
			{ctas.map((cta) => (
				<SelectPromotion promotion={cta.promotion} key={cta.ctaId}>
					<ButtonLink
						className={cx(styles.ctaButton, applyStylesIf(isLow, styles.low))}
						linkAttributes={{ ...cta.linkProps, href: cta.href }}
					>
						{cta.text}
					</ButtonLink>
				</SelectPromotion>
			))}
		</div>
	)
}
