'use client'

import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { PROMOTIONAL_BANNER_CONTENT_COLOR_TYPE } from 'services/graphql/contentStack/homes/query.type'
import { applyStylesIf, cx } from 'utils/cx'

import textStyles from '../../TextStyles.module.scss'
import styles from './PromotionalBannerText.module.scss'
import texts from 'fukku/styles/texts.module.scss'

interface PromotionalBannerTextProps {
	readonly subtitleTopKey: string
	readonly promotionTitle: string
	readonly isLargeDiscount: boolean
	readonly discount: string
	readonly subtitlesGroup: { titleKey: string; subtitleKey: string }[]
	readonly subtitleBottomKey: string
	readonly isLow: boolean
	readonly contentColorType: PROMOTIONAL_BANNER_CONTENT_COLOR_TYPE | null
	readonly customContentColor: string | null
}

export const PromotionalBannerText = ({
	subtitleTopKey,
	promotionTitle,
	isLargeDiscount,
	discount,
	subtitlesGroup,
	subtitleBottomKey,
	isLow,
	contentColorType,
	customContentColor,
}: PromotionalBannerTextProps) => {
	const { isLargeOrGreater } = useResponsive()
	const isPromoColor =
		contentColorType === PROMOTIONAL_BANNER_CONTENT_COLOR_TYPE.PROMO
	const isCustomColor = Boolean(
		contentColorType === PROMOTIONAL_BANNER_CONTENT_COLOR_TYPE.CUSTOM &&
			customContentColor
	)
	const isDefaultColor = !isPromoColor && !isCustomColor

	return (
		<div
			className={cx(
				styles.textContainer,
				applyStylesIf(isLow, styles.low),
				applyStylesIf(isPromoColor, styles.promoColor),
				applyStylesIf(isCustomColor, styles.customColor)
			)}
			style={
				{
					'--custom-content-color': `${customContentColor}`,
				} as React.CSSProperties
			}
		>
			{subtitleTopKey && (
				<div
					className={cx(
						styles.subtitleTop,
						isLargeOrGreater ? texts.bodyMRegular : texts.bodySRegular,
						applyStylesIf(isDefaultColor, textStyles.textShadow)
					)}
				>
					{subtitleTopKey}
				</div>
			)}
			{promotionTitle && (
				<div
					className={cx(
						styles.promoTitle,
						isLargeDiscount ? texts.promoTitleM : texts.promoTitleXL
					)}
				>
					{promotionTitle}
				</div>
			)}
			{discount && (
				<div className={cx(styles.discount, texts.promoTitleXL)}>
					{discount}
				</div>
			)}
			{subtitlesGroup && subtitlesGroup.length > 0 && (
				<ul className={styles.subtitlesGroup}>
					{subtitlesGroup.map(
						({ titleKey: itemTitleKey, subtitleKey: itemSubtitleKey }) => (
							<li
								key={`${itemTitleKey}-${itemSubtitleKey}`}
								className={styles.subtitlesGroupItem}
							>
								{itemTitleKey && (
									<span
										className={cx(
											styles.subtitlesGroupTitle,
											applyStylesIf(!isLargeOrGreater, texts.promoTitleXS),
											applyStylesIf(isDefaultColor, textStyles.textShadow)
										)}
									>
										{itemTitleKey}
									</span>
								)}
								{itemSubtitleKey && (
									<span
										className={cx(
											styles.subtitlesGroupSubtitle,
											isLargeOrGreater ? texts.bodyMRegular : texts.bodyS
										)}
									>
										{itemSubtitleKey}
									</span>
								)}
							</li>
						)
					)}
				</ul>
			)}
			{subtitleBottomKey && (
				<div className={texts.bodyM}>{subtitleBottomKey}</div>
			)}
		</div>
	)
}
