'use client'

import { BUTTON_VARIANT } from 'fukku/Button/Button.types'
import { ButtonIconTransparent } from 'fukku/Button/Icon/Transparent'
import { IconPauseL } from 'icons/components/IconPauseL'
import { IconPlayL } from 'icons/components/IconPlayL'
import { useContext, useEffect, useRef } from 'react'

import { MarqueeContext } from '../../../../../components/Marquee/MarqueeProvider'

import styles from './HeroBannerPrimaryLines.module.scss'

interface AnimationButtonProps {
	readonly titles: {
		play: string
		pause: string
	}
}

export const AnimationButton = ({ titles }: AnimationButtonProps) => {
	const { isAnimationPaused, toggleAnimation } = useContext(MarqueeContext)
	const buttonRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		function handleMouseDown(e: MouseEvent) {
			e.stopPropagation()
		}

		const element = buttonRef?.current
		if (element) {
			element.addEventListener('mousedown', handleMouseDown)
		}

		return () => {
			if (element) {
				element.removeEventListener('mousedown', handleMouseDown)
			}
		}
	}, [buttonRef])

	return (
		<div ref={buttonRef}>
			<ButtonIconTransparent
				variant={BUTTON_VARIANT.INVERSE}
				onClick={toggleAnimation}
				className={styles.pauseButton}
				title={isAnimationPaused ? titles.play : titles.pause}
			>
				{isAnimationPaused ? <IconPlayL /> : <IconPauseL />}
			</ButtonIconTransparent>
		</div>
	)
}
