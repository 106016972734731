import type {
	FetchedOrder,
	PurchaseDetail,
} from '../../types/purchase/purchase'

export const normalize = (
	purchaseDetail: FetchedOrder
): PurchaseDetail | undefined => {
	const { purchaseId, isMultiWarehouse, orders } = purchaseDetail
	if (!purchaseId) {
		return undefined
	}

	const isMultiOrder = orders?.length > 1 || !!isMultiWarehouse

	return {
		...purchaseDetail,
		isMultiOrder,
		isMultiWarehouse,
	}
}
