'use client'

import { useRef } from 'react'

import { useViewPromotion } from '../../hooks/useViewPromotion/useViewPromotion'
import type { Promotion } from '../../types/EEC'

type ViewPromotionProps = {
	readonly children: React.ReactNode
	readonly promotions: Promotion[]
}

export const ViewPromotion = ({ promotions, children }: ViewPromotionProps) => {
	const ref = useRef(null)
	useViewPromotion({ ref, promotions })

	return <div ref={ref}>{children}</div>
}
