'use client'

import { ButtonIconTransparent } from 'fukku/Button/Icon/Transparent'
import { useHorizontalScroll } from 'fukku/HorizontalScroll/hooks/useHorizontalScroll/useHorizontalScroll'
import { CLIENT_LINK_TYPE, ClientTextLink } from 'fukku/TextLink/client'
import { IconDropdownNextSmall } from 'icons/components/IconDropdownNextSmall'
import { IconDropdownPrevSmall } from 'icons/components/IconDropdownPrevSmall'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import type { SeoBannerLinkDisplay } from './SeoBanner'

import styles from './SeoBanner.module.scss'

interface SeoBannerListProps {
	readonly seoLinks: SeoBannerLinkDisplay[]
	readonly brandLabel: string
	readonly previousButtonLabel: string
	readonly nextButtonLabel: string
}

const id = 'seoBanner'

export function SeoBannerList({
	seoLinks,
	brandLabel,
	previousButtonLabel,
	nextButtonLabel,
}: SeoBannerListProps): React.ReactNode {
	const {
		horizontalScrollRef,
		horizontalScrollEvents,
		handleLeftScroll,
		handleRightScroll,
		isAtStart,
		isAtEnd,
	} = useHorizontalScroll()
	const { isLargeOrGreater } = useResponsive()

	return (
		<div className={styles.scrollWrapper}>
			{!isAtStart && (
				<ButtonIconTransparent
					onClick={handleLeftScroll}
					className={styles.buttonPrevious}
					disabled={isAtStart}
					aria-label={previousButtonLabel}
					data-testid={`seoBanner.${id}.previous.button`}
				>
					<IconDropdownPrevSmall className={styles.buttonIcon} />
				</ButtonIconTransparent>
			)}
			<ul
				{...horizontalScrollEvents}
				className={styles.linkList}
				ref={horizontalScrollRef as React.RefObject<HTMLUListElement>}
				data-testid={`seoBanner.${id}.container`}
				id={id}
			>
				{seoLinks.map((seoLink) => {
					const showLink = isLargeOrGreater
						? seoLink.show?.desktop
						: seoLink.show?.mobile
					if (showLink) {
						const linkTitle = `${seoLink.label} ${brandLabel}`
						return (
							<li key={seoLink.label} className={styles.listEl}>
								<ClientTextLink
									type={CLIENT_LINK_TYPE.INSPIRATIONAL}
									href={seoLink.href}
									className={styles.link}
									linkProps={{ title: linkTitle, 'aria-label': linkTitle }}
								>
									{seoLink.label}
								</ClientTextLink>
							</li>
						)
					}
					return null
				})}
			</ul>
			{!isAtEnd && (
				<ButtonIconTransparent
					onClick={handleRightScroll}
					className={styles.buttonNext}
					disabled={isAtEnd}
					aria-label={nextButtonLabel}
					data-testid={`seoBanner.${id}.next.button`}
				>
					<IconDropdownNextSmall className={styles.buttonIcon} />
				</ButtonIconTransparent>
			)}
		</div>
	)
}
