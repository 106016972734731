'use client'

import { useRef } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import { TwoColumnsCtasWrapper } from '../../ctas/TwoColumnsCtasWrapper'
import { useActiveLink } from '../../ctas/hooks/onActiveLink'

import ctaStyles from '../../ctas/SingleCtaOutlet.module.scss'
import styles from './HeroBannerCtas.module.scss'

type HeroBannerCtasComponentProps = {
	readonly href: string
	readonly displayText: string
	readonly heroBannerImage: React.ReactNode
}

export function HeroBannerCtasSingleCta({
	href,
	displayText,
	heroBannerImage,
}: HeroBannerCtasComponentProps): React.ReactNode {
	const ref = useRef<HTMLAnchorElement>(null)
	const activeLink = useActiveLink(ref)

	return (
		<a href={href} className={styles.bannerWrapper} ref={ref}>
			{heroBannerImage}
			<TwoColumnsCtasWrapper absolute>
				<div
					className={cx(
						ctaStyles.singleCta,
						applyStylesIf(activeLink, ctaStyles.active)
					)}
				>
					{displayText}
				</div>
			</TwoColumnsCtasWrapper>
		</a>
	)
}
