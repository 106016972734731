import { type RefObject, useEffect, useState } from 'react'

export const ORIENTATION_PORTRAIT_QUERY = '(orientation: portrait)'
const CHANGE_EVENT_NAME = 'change'

export const useSimpleVideoOrientation = (
	videoRef: RefObject<HTMLVideoElement>
) => {
	const isInitiallyPortrait =
		typeof window !== 'undefined' &&
		window.matchMedia(ORIENTATION_PORTRAIT_QUERY).matches

	const [showPortraitVideo, setShowPortraitVideo] =
		useState(isInitiallyPortrait)
	const [hasChangedOrientation, setHasChangedOrientation] =
		useState<boolean>(false)
	const [currentTime, setCurrentTime] = useState(0)

	useEffect(() => {
		const portraitMediaQuery = window.matchMedia(ORIENTATION_PORTRAIT_QUERY)

		const handleOrientationChange = () => {
			if (hasChangedOrientation) {
				return
			}
			setShowPortraitVideo(false)
			setHasChangedOrientation(true)
			if (videoRef.current) {
				setCurrentTime(videoRef.current.currentTime)
			}
		}

		if (isInitiallyPortrait) {
			portraitMediaQuery.addEventListener(
				CHANGE_EVENT_NAME,
				handleOrientationChange
			)
		}

		return () => {
			isInitiallyPortrait &&
				portraitMediaQuery.removeEventListener(
					CHANGE_EVENT_NAME,
					handleOrientationChange
				)
		}
	}, [])

	return { hasChangedOrientation, showPortraitVideo, currentTime }
}
